<template>
  <div class="left-col">
    <div class="left-col-content">
      <button class="add-video-btn" @click="showModalFunction">Add Video</button>
      <div class="rounded-box">
        <div class="box-header">Account:</div>
        <ul>
          <li v-for="link in links" :key="link.id">
            <a v-if="link.id != 3" :href="'#' + link.name.toLowerCase()" class="link">{{ link.name }}</a>
            <a v-else :href="'#'" class="link" @click="downloadAgreement" >{{ link.name }}</a>
          </li>
        </ul>
        <!-- <button type="button" @click="downloadAgreement" 
          class="agreement-btn hover:bg-green-800 font-medium rounded-lg text-sm px-2 py-2 mb-2 mt-2 ml-2"
        >Download Agreement</button> -->
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex justify-center items-center">
    <AddVideoStep1 v-show="currentStep === 1" :data="formData" @next="handleNext" @close="handleClose" />
    <AddVideoStep2 v-show="currentStep === 2" :data="formData" @next="handleNext" @previous="handlePrevious" @close="handleClose" />
    <AddVideoStep3 v-show="currentStep === 3" :data="formData" @complete="handleComplete" @previous="handlePrevious" @close="handleClose" />
  </div>
</template>

<script>
import AddVideoStep1 from './AddVideo/AddVideoStep1.vue';
import AddVideoStep2 from './AddVideo/AddVideoStep2.vue';
import AddVideoStep3 from './AddVideo/AddVideoStep3.vue';
import axios from 'axios';
import { ref } from 'vue';

const formData = ref({});

export default {
  components: {
    AddVideoStep1,
    AddVideoStep2,
    AddVideoStep3,
  },
  data() {
    return {
      links: [
        { id: 1, name: "Home" },
        { id: 2, name: "About" },
        { id: 3, name: "Current Agreement" },
      ],
      showModal: false,
      currentStep: 1,
    };
  },
  methods: {
    handleNext(stepData) {
      Object.assign(formData.value, stepData.data);
      if (this.currentStep < 3) {
        this.currentStep += 1;
      }
    },
    showModalFunction() {
      this.showModal = true;
    },
    handlePrevious() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    handleClose() {
      this.showModal = false;
      this.currentStep = 1;
      this.formData = {};
    },
    downloadAgreement() {
      const orgId = JSON.parse(localStorage.getItem("organization_ids")); // You need to define or fetch this value based on your application's logic
      const userId = localStorage.getItem('userId');  // Same as orgId, define or fetch based on your use case
      axios.post(axios.defaults.baseURL + 'account/lisynce/agreement', { orgId: orgId[0], userId: userId }, {
        responseType: 'blob'
      })
      .then(response => {
        // handle response, initiate file download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'signed_funeral_agreement.pdf'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Error downloading the agreement:', error);
        alert('Failed to download the agreement.');
      });
    },
    async handleComplete(emittedData) {
      try {
        const compositionsArray = formData.value.compositions.map(comp => ({
          id: comp.id,
          title: comp.id == 0 ? comp.title? comp.title : null : null,
          artist_names: comp.id == 0 ? comp.artist_names? comp.artist_names : null : null,
          composer_names: comp.id == 0 ? comp.composer_names? comp.composer_names : null : null,
          composerEmail: comp.id == 0 ? comp.composerEmail? comp.composerEmail : null : null
        }));
        const linksArray = emittedData.data.platformRows.map(link => ({
          platform: link.platform,
          video_link: link.videoLink,
          uploadDate: link.uploadDate,
          active: 1
        }));
        console.log(linksArray);
        // Prepare the data to be sent based on the formData structure
        const payload = {
          ensemble_id: formData.value.ensemble,
          organization_id: formData.value.school,
          description: emittedData.data.description,
          title: emittedData.data.title,
          programlink: '',
          linksArray: linksArray,
          compositions: compositionsArray
        };
        // Making the API call
        const response = await axios.post(`${axios.defaults.baseURL}video/upload-video-info/`, payload);

        // Handle the response as needed
        console.log('Video information uploaded successfully', response.data);

        // Resetting the modal and form data after successful upload
        this.showModal = false;
        this.currentStep = 1;
        this.formData = {};
        window.location.reload();
      } catch (error) {
        console.error('Error uploading video information:', error);
      }
    },
  },
};
</script>

<style scoped>
.left-col {
  flex: 1;
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.left-col-content {
  width: 75%;
}

.add-video-btn {
  width: 100%;
  background-color: #86bc25;
  color: white;
  padding: 10px;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
}

.agreement-btn {
  background-color: #86bc25;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 12px;
}

.rounded-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: left;
}

.box-header {
  background-color: rgb(230, 230, 230);
  padding: 8px;
  border-radius: 10px 10px 0 0;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  padding-left: 10px;
}

.link {
  color: blue;
  text-decoration: none;
}
</style>
