import { createStore } from "vuex";

export default createStore({
  state: {
    document: '',
    funeralForm: {}
  },
  mutations: {
    setDocument(state, document) {
      state.document = document;
    },
    setFuneralForm(state, funeralForm) {
      state.funeralForm = funeralForm;
    },
  },
  actions: {
    updateDocument({ commit }, document) {
      commit('setDocument', document);
    },
    updateFuneralForm({ commit }, funeralForm) {
      commit('setFuneralForm', funeralForm);
    }
  },
  getters: {
    getDocument: state => state.document,
    getFuneralForm: state => state.funeralForm
  },
  modules: {},
});
