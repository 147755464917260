<template>
  <Header />
  <div class="flex-row min-h-screen bg-white">
    <div class="nav-component">
      <NavComponent/>
    </div>
    <div class="flex-grow right-col p-4">
      <VideoTableComponent
        :tableConfig="tableConfig"
        :videos="videoData"
        :updateStatus="updateVideoStatus"
      />
    </div>
  </div>
</template>

<script>
import NavComponent from "./VideoManager/NavComponent.vue";
import VideoTableComponent from "./VideoManager/VideoTableComponent.vue";
import Header from "/src/components/Header.vue";
import VideoService from "@/services/VideoService";

export default {
  components: {
    NavComponent,
    VideoTableComponent,
    Header,
  },
  data() {
    return {
      // navLinks: [
      //   { id: 1, name: "Home" },
      //   { id: 2, name: "About" },
      //   { id: 3, name: "Download Agreement" },
      // ],
      tableConfig: {
        tableName: "Video Manager",
        columns: [
          {
            label: "id",
            name: "ID",
            type: "number",
            sortable: true,
            default: null,
          },
          {
            label: "title",
            name: "Title",
            type: "string",
            sortable: true,
            default: null,
          },
          {
            label: "ensemble_name",
            name: "Ensemble",
            type: "string",
            sortable: false,
            default: null,
          },
          {
            label: "networkchannel.name",
            name: "Channel",
            type: "string",
            sortable: true,
            default: null,
          },
          {
            label: "Event/Status",
            name: "Event",
            type: "string",
            sortable: true,
            default: "Live",
          },
        ],
      },
      videoData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const videos = await VideoService.videos();
      this.videoData = videos.data.tabs[0].results;
      console.log(this.videoData);
    },
    updateVideoStatus(videoId, newStatus) {
      const video = this.videoData.find((v) => v.ID === videoId);
      if (video) {
        video["Event/Status"] = newStatus;
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.flex-row {
  display: flex;
  width: 100%;
}

/* Assign flex-basis to NavComponent to take up 1/5th of the screen width */
.nav-component {
  flex: 0 0 20%; /* Do not grow or shrink and take up 20% of the space */
}

/* Make the right column take the remaining space */
.flex-grow {
  flex-grow: 1; /* Will grow to take the remaining space */
}

/* Ensure minimum height for the content area */
.min-h-screen {
  min-height: 100vh;
}

/* Remove the flex-shrink-0 if you want the flex items to be able to shrink */
.flex-shrink-0 {
  flex-shrink: 0;
}

/* Add this if you want to ensure the right column does not shrink */
.right-col {
  flex: 1 1 80%; /* Grow and shrink but initially take up 80% of the space */
}
</style>
