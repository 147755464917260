<template>
  <div class="container flex flex-col items-center pt-12 pb-12 px-4 md:px-0">
    <div class="w-full md:w-2/3 lg:w-2/2 mx-auto bg-gray-800 p-8 rounded-lg shadow-md">
      <h1 class="text-white text-2xl mb-4">Billing Information</h1>

      <!-- Amount Due Section -->
      <div class="mb-4">
        <h3 class="text-white text-xl">Amount Due: {{ funeralForm.amountDue }}</h3>
      </div>

      <!-- Credit Card Form -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

         <!-- Name on Card -->
         <div class="form-group">
          <label for="cardName" class="text-white">Name on Card *</label>
          <input
            v-model="cc.name"
            @blur="validateCardName"
            id="cardName"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardName}"
            placeholder="Full Name"
            autocomplete="cc-name"
          />
          <p v-if="cardErrors.cardName" class="text-red-500 text-sm">{{ cardErrors.cardName }}</p>
        </div>

        <!-- Card Number -->
        <div class="form-group">
          <label for="cardNumber" class="text-white">Card Number *</label>
          <div class="relative">
            <input
              v-model="cc.number"
              @input="formatCardNumber"
              @blur="validateCardNumber"
              @keyup="autoTab('cardExpiry')"
              id="cardNumber"
              class="w-full p-3 border rounded-lg bg-gray-700 text-white"
              :class="{'border-red-500': cardErrors.cardNumber}"
              placeholder="#### #### #### ####"
              autocomplete="cc-number"
            />
            <img
              v-if="cc.creditcardtype"
              :src="`/img/cc/sm/${cc.creditcardtype}.png`"
              alt="Card Type"
              class="absolute right-2 top-3 h-6"
            />
          </div>
          <p v-if="cardErrors.cardNumber" class="text-red-500 text-sm">{{ cardErrors.cardNumber }}</p>
        </div>

        <!-- Expiration Date -->
        <div class="form-group">
          <label for="cardExpiry" class="text-white">Expiration *</label>
          <input
            v-model="cc.expiry"
            @input="formatCardExpiry"
            @blur="validateCardExpiry"
            @keyup="autoTab('cardCvc')"
            id="cardExpiry"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardExpiry}"
            placeholder="MM / YYYY"
            autocomplete="cc-exp"
          />
          <p v-if="cardErrors.cardExpiry" class="text-red-500 text-sm">{{ cardErrors.cardExpiry }}</p>
        </div>

        <!-- CVC -->
        <div class="form-group">
          <label for="cardCvc" class="text-white">CVC Code *</label>
          <input
            v-model="cc.cvc"
            @input="formatCVC"
            @blur="validateCardCVC"
            id="cardCvc"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardCvc}"
            placeholder="CVC"
            autocomplete="cc-csc"
          />
          <p v-if="cardErrors.cardCvc" class="text-red-500 text-sm">{{ cardErrors.cardCvc }}</p>
        </div>
      </div>

      <!-- Billing Address Checkbox -->
      <div class="form-group mt-4">
        <label class="text-white">
          <input type="checkbox" v-model="sameAsLicenseeAddress" /> Billing address same as Licensee Address
        </label>
      </div>

      <!-- Billing Address Fields -->
      <div v-if="!sameAsLicenseeAddress" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div class="form-group">
          <label for="billingAddress" class="text-white">Street Address *</label>
          <input
            v-model="billingAddress.street"
            @input="fetchAddressSuggestions"
            @blur="validateBillingAddress"
            id="billingAddress"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.street}"
            placeholder="Street Address"
            autocomplete="street-address"
          />
          <ul v-if="addressSuggestions.length" class="absolute z-10 bg-white mt-1 rounded-md shadow-lg">
            <li
              v-for="suggestion in addressSuggestions"
              :key="suggestion.id"
              @click="selectAddressSuggestion(suggestion)"
              class="cursor-pointer p-3 hover:bg-gray-100"
            >
              {{ suggestion.place_name }}
            </li>
          </ul>
          <p v-if="billingErrors.street" class="text-red-500 text-sm">{{ billingErrors.street }}</p>
        </div>

        <!-- City -->
        <div class="form-group">
          <label for="billingCity" class="text-white">City *</label>
          <input
            v-model="billingAddress.city"
            id="billingCity"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.city}"
            placeholder="City"
            autocomplete="address-level2"
          />
          <p v-if="billingErrors.city" class="text-red-500 text-sm">{{ billingErrors.city }}</p>
        </div>

        <!-- State -->
        <div class="form-group">
          <label for="billingState" class="text-white">State *</label>
          <input
            v-model="billingAddress.state"
            id="billingState"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.state}"
            placeholder="State"
            autocomplete="address-level1"
          />
          <p v-if="billingErrors.state" class="text-red-500 text-sm">{{ billingErrors.state }}</p>
        </div>

        <!-- Zip Code -->
        <div class="form-group">
          <label for="billingZip" class="text-white">Zip Code *</label>
          <input
            v-model="billingAddress.zip"
            id="billingZip"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.zip}"
            placeholder="Zip Code"
            autocomplete="postal-code"
          />
          <p v-if="billingErrors.zip" class="text-red-500 text-sm">{{ billingErrors.zip }}</p>
        </div>

        <!-- Country -->
        <div class="form-group">
          <label for="billingCountry" class="text-white">Country *</label>
          <input
            v-model="billingAddress.country"
            id="billingCountry"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.country}"
            placeholder="Country"
            autocomplete="country-name"
          />
          <p v-if="billingErrors.country" class="text-red-500 text-sm">{{ billingErrors.country }}</p>
        </div>
      </div>

      <!-- Submit Button -->
      <button
        class="mt-4 bg-green-600 text-white py-3 px-6 rounded-lg w-full"
        @click="submitBilling"
      >
        Submit Payment
      </button>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axios from 'axios';

export default {
  name: 'CreditCardForm',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      userId: 0,
      cc: {
        name: '',
        number: '',
        expiry: '',
        cvc: '',
        creditcardtype: null
      },
      billingAddress: {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      addressSuggestions: [],
      sameAsLicenseeAddress: false,
      cardErrors: {
        cardName: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
      },
      billingErrors: {
        street: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      funeralForm: {}
    };
  },
  mounted() {
    this.funeralForm = this.$store.getters.getFuneralForm;

    // Check if funeralForm contains a licensee billing address string
    if (this.funeralForm && this.funeralForm.licenseeBillingAddress) {
      // Call the selectAddressSuggestion function to parse and fill the billing address fields
      this.selectAddressSuggestion({ place_name: this.funeralForm.licenseeBillingAddress });

      // Automatically check the "Billing address same as Licensee Address" checkbox
      this.sameAsLicenseeAddress = true;
    } else {
      // If no billing address in funeralForm, allow manual input
      this.sameAsLicenseeAddress = false;
    }
    if(this.funeralForm && this.funeralForm.contactFirstName && this.funeralForm.contactLastName) {
      this.cc.name = this.funeralForm.contactFirstName + ' ' + this.funeralForm.contactLastName;
    }
    this.init()
  },
  watch: {
    sameAsLicenseeAddress(newVal) {
      if (newVal) {
        // If checkbox is checked, fill in the billing address from funeralForm
        if (this.funeralForm && this.funeralForm.licenseeBillingAddress) {
          this.selectAddressSuggestion({ place_name: this.funeralForm.licenseeBillingAddress });
        }
        console.log(this.billingAddress)
      } else {
        // Clear billing address fields if checkbox is unchecked
        this.billingAddress.street = '';
        this.billingAddress.city = '';
        this.billingAddress.state = '';
        this.billingAddress.zip = '';
        this.billingAddress.country = '';
      }
    }
  },
  methods: {
    init() {
      axios.post(axios.defaults.baseURL + 'account/lisynce/getuserid', {email: this.funeralForm.contactEmail})
      .then(response => {
        //download the pdf agreement
        this.userId = response.data.user_id;
      })
      .catch(error => {
        console.error('Error retrieving userId:', error);
      });
    },
    // Format card number as XXXX XXXX XXXX XXXX
    formatCardNumber(event) {
      const value = event.target.value.replace(/\D/g, '').slice(0, 16); // Only digits, limit to 16
      this.cc.number = value.replace(/(\d{4})/g, '$1 ').trim(); // Add space every 4 digits
      this.setCardType();
    },

    // Format expiration date as MM/YY or MM/YYYY
    formatCardExpiry(event) {
      const value = event.target.value.replace(/\D/g, '').slice(0, 6); // Only digits, up to 6 (MMYYYY)
      if (value.length > 2) {
          this.cc.expiry = value.slice(0, 2) + '/' + (value.length > 4 ? value.slice(2, 6) : value.slice(2, 4));
      } else {
          this.cc.expiry = value;
      }
    },

    // Format CVC: Limit input to 3 digits (or 4 for Amex)
    formatCVC(event) {
      this.cc.cvc = event.target.value.replace(/\D/g, '').slice(0, 4); // Only digits, limit to 3 or 4
    },

    // Set the card type based on the number entered
    setCardType() {
      // const number = this.cc.number.replace(/\s/g, ''); // Remove spaces
      // if (/^4/.test(number)) {
      //   this.cc.creditcardtype = 'Visa';
      // } else if (/^5[1-5]/.test(number)) {
      //   this.cc.creditcardtype = 'MasterCard';
      // } else {
      //   this.cc.creditcardtype = null;
      // }
      const cleanNumber = this.cc.number.replace(/\D/g, ''); // Remove all non-digit characters
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Visa';
      } else if (/^5[1-5][0-9]{14}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'MasterCard';
      } else if (/^3[47][0-9]{13}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'American Express';
      } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Discover';
      } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Diners Club';
      } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'JCB';
      } else {
        this.cc.creditcardtype = null;
      }
    },

    // Luhn Algorithm to validate credit card number
    luhnCheck(number) {
      let sum = 0;
      let shouldDouble = false;
      for (let i = number.length - 1; i >= 0; i--) {
        let digit = parseInt(number.charAt(i));
        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }
      return sum % 10 === 0;
    },

    // Validate card number
    validateCardNumber() {
      const number = this.cc.number.replace(/\s/g, '');
      this.cardErrors.cardNumber = this.luhnCheck(number) && number.length == 16 ? null : 'Invalid Credit Card Number';
    },

    // Validate expiration date
    validateCardExpiry() {
      const [month, year] = this.cc.expiry.split('/');
      const currentYear = new Date().getFullYear(); // Full four-digit year
      const currentYearTwoDigit = currentYear % 100; // Last two digits of the current year
      const currentMonth = new Date().getMonth() + 1; // Get current month

      let fullYear; // To store the four-digit year based on input

      if (!month || !year || month < 1 || month > 12) {
          this.cardErrors.cardExpiry = 'Invalid Expiration Date';
          return;
      }

      if (year.length === 2) {
          // User entered two-digit year
          fullYear = 2000 + parseInt(year); // Convert to four-digit year
      } else if (year.length === 4) {
          // User entered four-digit year
          fullYear = parseInt(year);
      } else {
          this.cardErrors.cardExpiry = 'Invalid Expiration Date';
          return;
      }

      if (fullYear < currentYear || (fullYear === currentYear && month < currentMonth)) {
          this.cardErrors.cardExpiry = 'Expiration date has passed';
      } else {
          this.cardErrors.cardExpiry = null;
      }
    },

    // Validate CVC (3 digits for Visa/MasterCard, 4 for Amex)
    validateCardCVC() {
      const value = this.cc.cvc;
      const validLength = this.cc.creditcardtype === 'AmericanExpress' ? 4 : 3;
      this.cardErrors.cardCvc = value.length === validLength ? null : 'Invalid CVC';
    },

    // Auto tabbing logic
    autoTab(nextFieldId) {
      if (nextFieldId === 'cardExpiry') {
        this.validateCardNumber()
        if (!this.cardErrors.cardNumber) {
          const cardNumberField = document.getElementById('cardNumber');
          if (cardNumberField && this.cc.number.length >= 19) {
            document.getElementById(nextFieldId).focus();
          }
        }
      }
      if (nextFieldId === 'cardCvc') {
        this.validateCardExpiry()
        if (!this.cardErrors.cardExpiry && nextFieldId === 'cardCvc') {
          const expiryField = document.getElementById('cardExpiry');
          if (expiryField && (this.cc.expiry.length == 7 || this.cc.expiry.length == 5)) {
            document.getElementById(nextFieldId).focus();
          }
        }
      }
    },

    // Fetch address suggestions using Mapbox API
    fetchAddressSuggestions() {
      if (!this.billingAddress.street) {
        this.addressSuggestions = [];
        return;
      }

      const API_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.billingAddress.street)}.json?access_token=pk.eyJ1IjoiYnJ5YW4tcG90dHMiLCJhIjoiY2xuZWh6dnk1MGNyNjJvcGVjbzFzeGtpZCJ9.h1ubFAo_rM9sj59jy-OeCw`;

      axios.get(API_URL)
        .then(response => {
          this.addressSuggestions = response.data.features;
        })
        .catch(error => {
          console.error('Error fetching address suggestions:', error);
        });
    },

    //populate all fields
    selectAddressSuggestion(suggestion) {
      const pattern = /^([\w\s\.#\-]+),\s*([\w\s]+),\s*([A-Za-z\s]+)\s+(\d{5}),\s*([A-Za-z\s]+)$/;
      const match = suggestion.place_name.match(pattern);

      if (match) {
        this.billingAddress.street = match[1];  // street
        this.billingAddress.city = match[2];    // city
        this.billingAddress.state = match[3];   // state
        this.billingAddress.zip = match[4];     // zip
        this.billingAddress.country = match[5]; // country
      }

      this.addressSuggestions = [];
    },

    // Submit Billing Information
    submitBilling() {
      this.$v$.touch();
      if (this.$v$.invalid || !this.cc.number || !this.cc.expiry || !this.cc.cvc || !this.cc.name || this.billingErrors) {
        alert('Please fill in all required fields.');
        return;
      }

      // Example billing data submission
      const billingData = {
        number: this.cc.number,
        expiry: this.cc.expiry,
        cvc: this.cc.cvc,
      };

      console.log('Billing data submitted:', billingData);
      alert('Billing information submitted successfully');
    },
  },
  validations() {
    return {
      cc: {
        name: { required },
        number: { required },
        expiry: { required },
        cvc: { required },
      },
      billingAddress: {
        street: { required },
        city: { required },
        state: { required },
        zip: { required },
        country: { required },
      },
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  margin: 0 auto;
}

input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

input.border-red-500 {
  border-color: red !important;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4caf50;
}
</style>
